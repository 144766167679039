import styles from "./Footer.module.css";
import arrow from "../..//static/arrow.svg";
import audioEnabledIcon from "../../static/audio.svg";
import audioDisabledIcon from "../../static/audio-mute.svg";
import clsx from "clsx";

export default function Footer({
    isShuffling,
    toggleAudioCallback,
    audioEnabled,
}: {
    isShuffling: boolean;
    toggleAudioCallback: (e: any) => void;
    audioEnabled: boolean;
}) {
    return (
        <div className={styles.footer}>
            <div className={styles.left}>
                <a
                    href="https://leapgroupnetwork.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={arrow} alt="" />
                    Go to Main Site
                </a>
            </div>

            <div className={styles.center}>
                {isShuffling && (
                    <div className={styles.directions}>
                        <p>( CLICK ANYWHERE TO LEAP )</p>

                        <button
                            className={clsx({
                                [styles.audioToggle]: true,
                                "no-trigger": true,
                            })}
                            onClick={toggleAudioCallback}
                        >
                            Sound {audioEnabled ? "On" : "Off"}{" "}
                            <img
                                src={
                                    audioEnabled
                                        ? audioEnabledIcon
                                        : audioDisabledIcon
                                }
                                className={styles.audio}
                                alt="toggle audio"
                            />
                        </button>
                    </div>
                )}
            </div>

            <div className={styles.right}>
                <ul className={styles.socialList}>
                    <li className={styles.socialItem}>
                        <a
                            href="https://www.linkedin.com/company/leap-group/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            LinkedIn
                        </a>
                    </li>
                    <li className={styles.socialItem}>
                        <a
                            href="https://www.instagram.com/leapgroupnetwork/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Instagram
                        </a>
                    </li>
                    <li className={styles.socialItem}>
                        <a
                            href="https://www.facebook.com/leapgroupnetwork"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Facebook
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}
